<template>
  <v-breadcrumbs
    v-dragscroll.x
    class="py-0 section__title sv-breadcrumbs"
    :items="items"
    :divider="divider"
    :dark="dark"
  >
    <slot name="default"></slot>
  </v-breadcrumbs>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    divider: {
      type: String,
      default: "/",
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.sv-breadcrumbs.v-breadcrumbs {
  flex-wrap: nowrap;
  overflow-x: hidden;
  white-space: nowrap;
  user-select: none;
  cursor: grab;

  .v-breadcrumbs__item,
  .v-breadcrumbs__divider {
    font-size: 16px;
  }
  &.theme--light a.v-breadcrumbs__item {
    color: var(--v-textBase-base);
  }
  &.theme--dark a.v-breadcrumbs__item {
    color: white;
  }
  .v-breadcrumbs__item--disabled {
    color: var(--v-textLight-base) !important;
  }
}
</style>
