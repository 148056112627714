<template>
  <div class="orbits">
    <div v-for="index in count" :key="index" :style="getStyle(index - 1)" />
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 4,
    },
    sizes: {
      type: Array,
      default: () => [150, 300, 450, 600, 750, 900, 1050, 1200],
    },
    colors: {
      type: Array,
      default: () => [
        "#CEE8FC",
        "#D4EBFC",
        "#DAEEFD",
        "#E0F1FD",
        "#E7F4FE",
        "#F3F9FE",
        "#F3F9FE",
        "#F3F9FE",
      ],
    },
    borderWidth: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    getStyle() {
      return (index) => {
        const radius = this.sizes[index] || 0;
        const color = this.colors[index] || "white";
        return {
          "border-radius": `${radius}px`,
          width: `${radius * 2}px`,
          height: `${radius * 2}px`,
          "margin-left": `${-radius}px`,
          "margin-top": `${-radius}px`,
          border: `${this.borderWidth}px solid ${color}`,
        };
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.orbits {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div {
    position: absolute;
    left: 50%;
    top: 50%;
  }
}
</style>
