<template>
  <v-card
    tile
    elevation="0"
    color="corporateBlack"
    height="100%"
    class="footer"
  >
    <v-container class="text-left pa-0" fluid>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" class="pr-3 px-lg-0 page-block-wrapper">
              <router-link
                class="py-4 py-md-0 subtitle2 white--text rlink"
                to="/solutions"
              >
                {{ translations.solutions }}
              </router-link>
              <router-link
                class="py-4 py-md-0 subtitle2 white--text rlink solutions-left-margin"
                to="/partnership"
              >
                {{ translations.partnership }}
              </router-link>
              <router-link
                class="py-4 py-md-0 subtitle2 white--text rlink solutions-left-margin"
                to="/support"
              >
                {{ translations.support }}
              </router-link>
              <router-link
                class="py-4 py-md-0 subtitle2 white--text rlink solutions-left-margin"
                to="/contacts"
              >
                {{ translations.contacts }}
              </router-link>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider
        class="mt-10 mb-8"
        style="border-color: rgba(255, 255, 255, 0.12)"
      />
      <v-row justify="space-between">
        <v-col
          v-if="translations.email.length > 0"
          style="white-space: nowrap"
          class="contacts-block d-flex align-center"
        >
          <img class="mr-3" :src="`${baseUrl}img/mail.svg`" alt="mail" /><a
            class="white--text"
            :href="`mailto:${translations.email}?subject=${translations.emailSubject}`"
            >{{ translations.email }}</a
          >
        </v-col>
        <v-col
          v-if="translations.phone.length > 0"
          style="white-space: nowrap"
          class="contacts-block d-flex align-center"
        >
          <img class="mr-3" :src="`${baseUrl}img/phone.svg`" alt="phone" />
          <a class="white--text" :href="`tel:${translations.phone}`">{{
            translations.phone
          }}</a>
        </v-col>
        <v-col
          v-if="translations.address.length > 0"
          class="contacts-block address-block d-flex align-center"
        >
          <img
            class="mr-3"
            :src="`${baseUrl}img/placemark.svg`"
            alt="placemark"
          />
          <a class="white--text" target="_blank" :href="translations.maplink">{{
            translations.address
          }}</a>
        </v-col>
        <v-col style="white-space: nowrap" class="contacts-block pull-right">
          <a
            v-for="(link, index) in data.links"
            :key="index"
            :href="link.fields.link"
          >
            <img
              width="30"
              height="30"
              :class="index != data.links.length - 1 ? 'mr-6' : ''"
              :src="link.fields.logo"
              alt="logo"
            />
          </a>
        </v-col>
      </v-row>
      <v-divider
        class="mt-8 mb-8"
        style="border-color: rgba(255, 255, 255, 0.12)"
      />
      <v-row>
        <v-col cols="12" md="5"
          ><span class="white--text"
            >© {{ translations.svyazcom }} {{ year }}</span
          ></v-col
        >
        <v-col cols="12" md="7" class="text-md-right"
          ><router-link class="white--text" to="/privacy-policy">{{
            translations.personalData
          }}</router-link></v-col
        >
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    translations() {
      let obj = this.$store.getters.getMenu;
      let footer = this.$store.getters.getFooter;
      return {
        solutions: obj.children.find((x) => x.node_name == "solutions")?.fields
          .title,
        partnership: obj.children.find((x) => x.node_name == "partnership")
          ?.fields.title,
        support: obj.children.find((x) => x.node_name == "support")?.fields
          .title,
        contacts: obj.children.find((x) => x.node_name == "contacts")?.fields
          .title,
        address: footer.fields.address || "",
        svyazcom: footer.fields.company || "",
        email: footer.fields.email || "",
        emailSubject: "From%20WWW",
        maplink: footer.fields.maplink,
        personalData: footer.fields.personal_data_title,
        phone: footer.fields.phone || "",
      };
    },
    year() {
      return new Date().getFullYear();
    },
    data() {
      let footer = this.$store.getters.getFooter;
      return {
        links: footer.children,
      };
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>

<style scoped>
.footer {
  z-index: 5;
}
>>> a:hover {
  color: rgba(255, 255, 255, 0.76) !important;
}
>>> a:hover img {
  opacity: 0.76;
}
.contacts-block {
  flex-basis: 100%;
}
.address-block {
  white-space: normal;
}
.address-block img {
  align-self: start;
  margin-top: 6px;
}
.page-block-wrapper {
  flex-basis: 100%;
  white-space: normal;
}
.solutions-left-margin {
  margin-left: 0px;
}
.rlink {
  display: block;
}
.rlink:first-child {
  margin-left: 0;
}
.page-block {
  flex-basis: 100%;
  padding: 20px 12px 0 12px;
}
@media (min-width: 1024px) {
  .address-block {
    white-space: nowrap;
  }
  .pt-md-3.page-block {
    padding-top: 15px !important;
  }
  .page-block {
    flex-basis: 0;
    padding: 12px 10px;
  }
  .page-block-wrapper {
    white-space: nowrap;
  }
  .rlink {
    display: inline;
  }
  .rlink:first-child {
    margin-left: 0;
  }
  .solutions-left-margin {
    margin-left: 120px;
  }
}

@media (min-width: 1280px) {
  .address-block img {
    margin-top: 10px;
  }
  .contacts-block {
    flex-basis: 0;
  }
  .page-block-wrapper {
    flex-basis: 0;
    white-space: nowrap;
  }
  .pull-right {
    text-align: right;
  }
  .solutions-left-margin {
    margin-left: 120px;
  }
  .rlink:first-child {
    margin-left: 16px;
  }
}
</style>
