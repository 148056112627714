<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4221 3.57464C14.3222 -0.524878 7.67489 -0.524879 3.57496 3.57464C-0.524986 7.67416 -0.524986 14.3208 3.57496 18.4203C7.34674 22.1917 13.2741 22.4935 17.3912 19.326L22.1858 24.1201C22.6923 24.6266 23.5136 24.6266 24.0201 24.1201L24.1201 24.0202C24.6266 23.5137 24.6266 22.6925 24.1201 22.186L19.3258 17.3922C22.4959 13.2754 22.1947 7.34686 18.4221 3.57464ZM5.40926 5.40882C8.49613 2.32226 13.5009 2.32226 16.5878 5.40882C19.6747 8.49536 19.6747 13.4996 16.5878 16.5862C13.5009 19.6727 8.49613 19.6727 5.40926 16.5862C2.32241 13.4996 2.32241 8.49536 5.40926 5.40882Z"
      fill="#2E323A"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 25,
    },
    height: {
      type: [Number, String],
      default: 25,
    },
  },
};
</script>
