<template>
  <v-menu v-model="opened" content-class="rounded-0" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <header-menu-item
        :show="show"
        :hovered="['/about', '/news', '/career'].includes(currentRoute)"
        :menu-bind="attrs"
        :menu-on="on"
        :dark="dark"
      >
        <span
          class="menu"
          :style="{
            color: opened ? 'var(--v-corporateBlue-base) !important' : '',
          }"
          >{{ item.fields.title }}
        </span>
        <v-icon v-if="!opened" class="chevron" :class="{ dark }"
          >mdi-chevron-down</v-icon
        >
        <v-icon v-if="opened" class="chevron active" :class="{ dark }"
          >mdi-chevron-up</v-icon
        >
      </header-menu-item>
    </template>

    <v-list class="menuListItems" :class="{ dark }">
      <v-list-item
        v-for="(subItem, index) in item.children"
        :key="index"
        :class="{ dark }"
        class="dropdownLink"
        link
        exact
        :to="getTo(subItem)"
      >
        <v-list-item-title>{{ subItem.fields.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import HeaderMenuItem from "./HeaderMenuItem.vue";
export default {
  components: { HeaderMenuItem },
  props: {
    item: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
  methods: {
    getTo(item) {
      const parts = item.fields.href.split("#");
      if (parts[1]) {
        return {
          path: `/${parts[0]}`,
          hash: parts[1],
        };
      } else {
        return { path: `/${item.fields.href}` };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.menuListItems {
  padding: 6px !important;
}
.menuListItems.dark {
  background: var(--v-corporateGrey-base) !important;
}
.dropdownLink.dark {
  color: white !important;
}
.dropdownLink:hover {
  background: rgba(33, 150, 243, 0.08);
}
.chevron {
  color: var(--v-corporateGrey-base) !important;
}
.chevron.active {
  color: var(--v-corporateBlue-base) !important;
}
.menu:active {
  color: var(--v-corporateBlue-base) !important;
}
.dark .menu {
  color: white !important;
}
.dark .chevron {
  color: white;
}
</style>
