import Repository from "@/plugins/axios";

export default {
  // Получить список решений
  getSolutionsList() {
    return Repository.get("/solutions");
  },

  // Получить полную информацию о решении
  // solutionName -- это поле node_name решения
  getSolutionItem(solutionName) {
    return Repository.get(`/solutions/${solutionName}`);
  },
};
