<template>
  <v-row class="align-stretch">
    <v-col cols="12" lg="7">
      <v-row>
        <v-col>
          <solution-card :solution-id="favSolutions[0] || 0" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <solution-card :solution-id="favSolutions[1] || 0" />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="5">
      <solution-card :solution-id="favSolutions[2] || 0" style="height: 100%" />
    </v-col>
  </v-row>
</template>

<script>
import SolutionCard from "@/components/SolutionCard/SolutionCard.vue";
export default {
  components: {
    SolutionCard,
  },
  props: {
    favSolutions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
