<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M6.27797 9.03887L10.4798 11.4648L1.90729 14.2123L0 5.4137L3.47834 7.42194C5.55228 3.87973 8.857 1.49965 12.5369 0.513707C16.2439 -0.479947 20.3301 -0.0589197 23.9167 2.01135C27.5022 4.08198 29.9102 7.41056 30.9035 11.1179C30.9244 11.1954 30.9446 11.2732 30.9641 11.351L27.8767 12.3407C27.8473 12.2114 27.8146 12.083 27.7801 11.9549C27.0012 9.04695 25.1126 6.43636 22.299 4.81208C19.4858 3.18743 16.2802 2.85744 13.3727 3.63636C10.4923 4.4083 7.90556 6.27008 6.27797 9.03887Z"
        fill="#404040"
      />
      <path
        d="M33.0066 24.7752L29.3169 22.6455C27.2411 26.1502 23.9551 28.5072 20.2984 29.4869C16.591 30.4802 12.5044 30.0595 8.91813 27.9885C5.53045 26.0331 3.19516 22.9545 2.10938 19.4949L5.18835 18.5082C6.03738 21.2286 7.87162 23.6505 10.5351 25.1878C13.3483 26.8124 16.5535 27.1424 19.4611 26.3631C22.3176 25.5978 24.8867 23.7603 26.5161 21.0285L22.5254 18.7248L31.099 15.9766L33.0066 24.7752Z"
        fill="#404040"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect :width="width" :height="height" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 34,
    },
    height: {
      type: [Number, String],
      default: 30,
    },
  },
};
</script>
