<template>
  <div v-if="pageLoaded">
    <v-row>
      <v-col>
        <span class="heading1">{{ pageData.fields.title }}</span>
      </v-col>
    </v-row>

    <v-row class="align-stretch">
      <v-col cols="12" lg="7" xl="7">
        <div
          class="cut-last-margin corporateBlue-lighten4"
          :class="
            $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
              ? 'pl-16 pr-16 pt-10 pb-10'
              : 'pl-5 pr-5 pt-5 pb-5'
          "
          style="z-index: 2; height: 100%"
        >
          <v-row no-gutters class="">
            <v-col>
              <div v-html="pageData.fields.full_text" />
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col xs="12" lg="5">
        <validation-observer ref="formCommunicationValidator">
          <v-row>
            <v-col class="pt-0 pb-0 mb-2">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                mode="eager"
                :name="pageData.fields.login_title"
              >
                <v-text-field
                  v-model="login"
                  :label="pageData.fields.login_title"
                  maxlength="50"
                  clearable
                  clear-icon="mdi-close-circle"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0 mb-2">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                mode="eager"
                :name="pageData.fields.password_title"
              >
                <v-text-field
                  v-model="password"
                  :label="pageData.fields.password_title"
                  maxlength="25"
                  clearable
                  clear-icon="mdi-close-circle"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="12" md="" class="flex-grow-0">
              <v-btn
                depressed
                x-large
                color="primary"
                block
                @click="commitForm"
                >{{ pageData.fields.button_title }}</v-btn
              >
            </v-col>
          </v-row>
        </validation-observer>
      </v-col>
    </v-row>

    <message-modal
      v-model="dialogLogin"
      :title="dialogLoginTitle"
      :text="dialogLoginText"
    />
  </div>
</template>

<script>
import SupplementRepository from "@/api/supplement.js";
import MessageModal from "@/components/MessageModal.vue";

export default {
  components: { MessageModal },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
      },
      login: "",
      password: "",
      dialogLogin: false,
      dialogLoginTitle: "",
      dialogLoginText: "",
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await SupplementRepository.getSupport();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
    async commitForm() {
      const { login, password } = this;
      const { data } = await SupplementRepository.loginSupport(login, password);

      this.dialogLoginTitle = data.dialog_title;
      this.dialogLoginText = data.dialog_text;
      this.dialogLogin = true;
    },
  },
};
</script>
