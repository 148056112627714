<template>
  <v-card tile elevation="0" color="primaryAdditional4" height="100%">
    <v-container class="text-left pa-10">
      <v-row>
        <v-col class="pb-0">
          <span class="subtitle1">{{ contactData.title }}</span>
        </v-col>
      </v-row>
      <v-row v-if="contactData.addr.length > 0">
        <v-col class="pb-0">
          <span>{{ contactData.addr }}</span>
        </v-col>
      </v-row>
      <v-row v-if="contactData.maplink.length > 0">
        <v-col class="pb-0">
          <a :href="contactData.maplink" target="_blank">{{
            $t("Contacts.viewOnMap")
          }}</a>
        </v-col>
      </v-row>
      <v-row v-if="contactData.phone.length > 0">
        <v-col class="pb-0">
          <a :href="`tel:${contactData.phone}`">{{ contactData.phone }}</a>
        </v-col>
      </v-row>
      <v-row v-if="contactData.email.length > 0">
        <v-col class="pb-0">
          <a :href="`mailto:${contactData.email}?subject=${emailSubject}`">{{
            contactData.email
          }}</a>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    contactData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      emailSubject: "From%20WWW",
    };
  },
};
</script>
