<template>
  <div class="d-flex align-center flex-nowrap">
    <div class="sv-masked-icon" :style="style" />
    <a
      v-if="href.length"
      class="btn-text pl-3 text-no-wrap"
      :style="`color: ${color}`"
      :href="href"
      target="_blank"
    >
      {{ title || "" }}
      <v-icon :style="`color: ${color}`">mdi-chevron-right</v-icon>
    </a>
    <div
      v-else
      class="clickable btn-text pl-3 text-no-wrap"
      :style="`color: ${color}`"
      v-on="$listeners"
    >
      {{ title || "" }}
      <v-icon :style="`color: ${color}`">mdi-chevron-right</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "SvBadge",
  props: {
    maskIcon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "var(--v-corporateBlue-base)",
    },
  },
  computed: {
    style() {
      return {
        mask: `url(${this.maskIcon}) no-repeat center / contain`,
        "-webkit-mask": `url(${this.maskIcon}) no-repeat center / contain`,
        "background-color": this.color,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.sv-masked-icon {
  width: 44px;
  height: 50px;
  display: inline-flex;
}
</style>
