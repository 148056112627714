<template>
  <div v-if="pageLoaded">
    <article-with-contents
      ref="refarticle"
      :contents="pageData.solution.fields.contents"
      :breadcrumbs="breadcrumbsItems"
      class="section"
    >
      <v-row>
        <v-col>
          <span class="heading1">{{ pageData.solution.fields.title }}</span>
        </v-col>
      </v-row>
      <v-row v-if="pageData.solution.fields.subtitle">
        <v-col class="pt-0">
          <span class="subtitle1">{{ pageData.solution.fields.subtitle }}</span>
        </v-col>
      </v-row>
      <v-row style="position: relative; z-index: 5">
        <v-col>
          <masked-icon-link
            v-if="(pageData.solution.fields.presentation || '').length > 0"
            :mask-icon="`${baseUrl}img/pdf.svg`"
            :title="pageData.solutions_page.fields.button_presentation || ''"
            :href="pageData.solution.fields.presentation"
          />
          <masked-icon-link
            v-else
            :mask-icon="`${baseUrl}img/pdf.svg`"
            :title="pageData.solutions_page.fields.button_presentation || ''"
            @click="showDialog = true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <kk-dynamic-html :html="pageData.solution.fields.full_text" />
        </v-col>
      </v-row>
    </article-with-contents>

    <form-communication
      :form-id="formId"
      :person-set="pageData.solution.links.person || []"
    />

    <form-communication-modal
      v-model="showDialog"
      :dialogue="presentationDialogue"
    />
  </div>
</template>

<script>
import SolutionsRepository from "@/api/solutions.js";
import FormCommunication from "@/components/FormCommunication.vue";
import ArticleWithContents from "@/components/Article/ArticleWithContents.vue";
import MaskedIconLink from "@/components/MaskedIconLink.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import FormCommunicationModal from "@/components/FormCommunicationModal.vue";
export default {
  components: {
    FormCommunication,
    FormCommunicationModal,
    ArticleWithContents,
    MaskedIconLink,
    KkDynamicHtml,
  },
  props: {
    ident: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(
        this.pageData.solutions_page.fields.title
      ),
    };
  },
  data() {
    return {
      showDialog: false,
      pageLoaded: false,
      pageData: {
        main_page: {
          fields: {},
        },
        solutions_page: {
          fields: {},
          links: { feedback_form: [] },
        },
        solution: { fields: {}, links: { person: [] } },
      },
    };
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_page.fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.solutions_page.fields.title || "",
          to: { name: "Solutions" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.solution.fields.title || "",
          disabled: true,
        },
      ];
    },
    presentationDialogue() {
      return this.$store.getters.getPresentationDialogue;
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
    formId() {
      if (
        this.pageData.solution &&
        this.pageData.solution.links &&
        this.pageData.solution.links.feedback_form
      ) {
        if (this.pageData.solution.links.feedback_form.length > 0) {
          return this.pageData.solution.links.feedback_form[0];
        }
      }
      return this.pageData.solutions_page.links.feedback_form[0] || 0;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    return this.loadPage();
  },
  methods: {
    async loadPage() {
      try {
        const { data } = await SolutionsRepository.getSolutionItem(this.ident);
        this.pageData = data;
        this.$store.commit("setLangsAvail", data.solution.published);
        this.pageLoaded = true;
        this.$emit("page-loaded");
        this.$nextTick(() => {
          this.$refs.refarticle.initObserver();
        });
      } catch (error) {
        return true;
      }
    },
  },
};
</script>
