<template>
  <div class="slideshow-container">
    <v-img
      class="slideshow-image-pad"
      :style="{ 'z-index': padAbove ? 7 : 5 }"
      :src="imagePad"
      eager
      @load="$emit('trigger-resize')"
    />
    <v-img
      v-for="(image, index) in images"
      :key="index"
      class="slideshow-image"
      :class="itemClass(index)"
      :src="image"
      :style="imageStyle"
    />
  </div>
</template>

<script>
export default {
  name: "ImageSlideShow",
  props: {
    // ожидается массив строк SRC изображений
    images: {
      type: Array,
      required: true,
    },
    // изображение-подложка
    imagePad: {
      type: String,
      required: true,
    },
    // продолжительность показа изображения
    showDuration: {
      type: Number,
      default: 4,
    },
    // продолжительность процесса перехода изображения
    // в следующее
    fadeDuration: {
      type: Number,
      default: 1,
    },
    // доп. стили изображения (как правило,
    // смещение и размер относительно подложки)
    itemStyle: {
      type: Object,
      default: () => {},
    },
    // подложка впереди изображений?
    padAbove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
      time: 0,
    };
  },
  computed: {
    itemClass() {
      return (index) => {
        const animationTime =
          this.time % (this.showDuration * this.images.length);
        return {
          active: index == Math.floor(animationTime / this.showDuration),
        };
      };
    },
    imageStyle() {
      return {
        transition: `all ${this.fadeDuration}s cubic-bezier(0.25, 0.1, 0.25, 1)`,
        ...this.itemStyle,
      };
    },
  },
  mounted() {
    this.startTimer();
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.time++;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
  },
};
</script>

<style lang="scss" scoped>
.slideshow-container {
  position: relative;

  .slideshow-image {
    position: absolute !important;
    z-index: 6;
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }
}
</style>
