<template>
  <a
    :href="linkFixed"
    :target="isExternal || matchStorage || newWindow ? '_blank' : '_self'"
    @click="onClick"
  >
    <span v-if="innerHtml.length > 0" v-html="innerHtml" />
    <slot v-else />
  </a>
</template>
<script>
export default {
  name: "KkLink",
  props: {
    link: {
      type: String,
      required: true,
    },
    router: {
      type: Object,
      default: null,
    },
    newWindow: {
      type: Boolean,
      default: false,
    },
    innerHtml: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      home: "(svyazcom.ru|svyazcom.org|soft.sc)",
    };
  },
  computed: {
    matchHttp() {
      return this.link.match(new RegExp(`^https?://(.+)$`));
    },
    matchHome() {
      return this.link.match(new RegExp(`^https?://(www.|)${this.home}(.*)$`));
    },
    matchIp() {
      return this.link.match(
        new RegExp(
          "^https?://\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}(:\\d{1,5}|)(/.*)$"
        )
      );
    },
    isExternal() {
      return this.matchHttp && !this.matchHome && !this.matchIp;
    },
    matchStorage() {
      return this.linkFixed.startsWith("/storage");
    },
    linkFixed() {
      if (this.matchHome) {
        return this.matchHome[3];
      } else if (this.matchIp) {
        return this.matchIp[2];
      } else {
        return this.link;
      }
    },
  },
  methods: {
    onClick(event) {
      const router = this.router === null ? this.$router : this.router;
      if (!this.isExternal && !this.newWindow) {
        if (this.matchStorage) {
          return true;
        } else {
          event.preventDefault();
          if (this.$route && this.$route.path == this.linkFixed) {
            return true;
          }
          router.push({ path: this.linkFixed });
        }
      }
      return true;
    },
  },
};
</script>
