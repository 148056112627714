import Vue from "vue";
import Router from "vue-router";
import Store from "@/store";
import Main from "@/pages/Main";
import Contacts from "@/pages/Contacts";
import Solutions from "@/pages/Solutions";
import Solution from "@/pages/Solution";
import Search from "@/pages/Search";
import Partnership from "@/pages/Partnership";
import Support from "@/pages/Support";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import CustomPage from "@/pages/CustomPage";
import Thanks from "@/pages/CrossPages/Thanks.vue";
import PageNotFound from "@/pages/CrossPages/PageNotFound.vue";
import { sync as RouterSync } from "vuex-router-sync";
import { elementScrollIntoView } from "seamless-scroll-polyfill";

Vue.use(Router);

// Создание экземпляра Router
const SvRouter = new Router({
  mode: "history",
  base: process.env.VUE_APP_CLI_PUBLIC_PATH || undefined,

  // При переходе скролить к верху страницы или к Хэшу / ID
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return {
        ...savedPosition,
        behavior: "instant",
      };
    }

    if (to.hash) {
      const element = document.getElementById(to.hash.substring(1));
      if (element) {
        elementScrollIntoView(element, { behavior: "smooth" });
        return true;
      }
    }

    // не работает, все мозги сломал return { x: 0, y: 0, behavior: 'instant' };
    // не работает, все мозги сломал return { left: 0, top: 0, behavior: 'instant' };

    // единственное что работает
    document.getElementById("app").scrollIntoView({ behavior: "instant" });

    return false;
  },

  routes: [
    {
      path: "/",
      name: "Main",
      component: Main,
    },
    {
      path: "/contacts",
      name: "Contacts",
      component: Contacts,
    },
    {
      path: "/solutions",
      component: Solutions,
      name: "Solutions",
    },
    {
      path: "/partnership",
      component: Partnership,
      name: "Partnership",
    },
    {
      path: "/support",
      component: Support,
      name: "Support",
    },
    {
      path: "/search",
      name: "Search",
      component: Search,
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: PrivacyPolicy,
    },
    {
      path: "/thanks",
      name: "Thanks",
      component: Thanks,
    },
    {
      path: "/pages/:ident",
      name: "CustomPage",
      component: CustomPage,
      props: true,
    },
    {
      path: "/404",
      name: "PageNotFound",
      component: PageNotFound,
    },
    {
      path: "/:ident",
      name: "Solution",
      component: Solution,
      props: true,
    },
    {
      path: "*",
      name: "NoRoute",
      redirect: () => {
        return `/404`;
      },
    },
  ],
});

RouterSync(Store, SvRouter);
Vue.router = SvRouter;

export default SvRouter;
