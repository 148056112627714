import Repository from "@/plugins/axios";

export default {
  // Получить необходимый контент для запуска приложения ("базовый запрос")
  // В числе прочего отдаст индексы, которые используются на других страницах в ссылках (links):
  // - персоналий
  // - клиентов
  // - продуктов
  // - категорий продуктов
  getBase() {
    return Repository.get("/base");
  },

  // Сменить язык (code = [ru|en])
  setLang(code) {
    return Repository.post("/lang", { code });
  },
};
