<template>
  <div>
    <div ref="menu_button" class="menu-control" @click="opened = !opened">
      <img v-show="!opened && !dark" :src="`${baseUrl}img/burger.svg`" />
      <img v-show="opened && !dark" :src="`${baseUrl}img/cross.svg`" />
      <img v-show="!opened && dark" :src="`${baseUrl}img/burger-dark.svg`" />
      <img v-show="opened && dark" :src="`${baseUrl}img/cross-dark.svg`" />
    </div>

    <transition name="slide">
      <div v-show="opened" ref="menu" class="mobileMenu">
        <div class="mobileMenuContainer">
          <header-menu-options :menu="menu" class="mobileMenuRow" />

          <header-menu-search
            :placeholder="menu.fields.search"
            class="mobileMenuRow mt-4 mb-5"
            @on-search="doSearch"
          />

          <div
            v-for="(item, index) in menu.children"
            :key="index"
            class="mobileMenuRow"
          >
            <div
              v-if="item.children && item.children.length > 0"
              class="mobileMenuSection"
            >
              <div class="mobileMenuItem">
                {{ item.fields.title }}
              </div>
              <div
                v-for="(subItem, subIndex) in item.children"
                :key="subIndex"
                class="mobileMenuRow"
              >
                <router-link class="mobileMenuSubItem" :to="getTo(subItem)">
                  {{ subItem.fields.title }}
                </router-link>
              </div>
            </div>

            <router-link v-else class="mobileMenuItem" :to="getTo(item)">
              {{ item.fields.title }}
            </router-link>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import HeaderMenuOptions from "./HeaderMenuOptions.vue";
import HeaderMenuSearch from "./HeaderMenuSearch.vue";
export default {
  components: { HeaderMenuOptions, HeaderMenuSearch },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSearch: false,
      opened: false,
    };
  },
  computed: {
    translations() {
      return {
        search: this.menu.fields.search,
      };
    },
    currentRoute() {
      return this.$route.path;
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
  mounted() {
    let local = this;
    window.addEventListener("click", function (event) {
      if (
        (!local.$refs.menu?.contains(event.target) &&
          !local.$refs.menu_button?.contains(event.target)) ||
        (local.$refs.menu?.contains(event.target) &&
          event.target.nodeName == "A")
      ) {
        local.opened = false;
      }
    });
  },
  methods: {
    doSearch(searchTerm) {
      if (searchTerm.length > 0) {
        this.opened = false;
        this.$emit("on-search", searchTerm);
      }
    },
    getTo(item) {
      const parts = item.fields.href.split("#");
      if (parts[1]) {
        return {
          path: `/${parts[0]}`,
          hash: parts[1],
        };
      } else {
        return { path: `/${item.fields.href}` };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mobileMenuItem,
.mobileMenuSubItem {
  margin-top: 6px;
  margin-bottom: 6px;
}
.mobileMenuSection {
  margin-bottom: 14px;
  margin-top: 2px;
}
.mobileMenuSubItem {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000 !important;
}
.mobileMenuItem {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #000000 !important;
}
.mobileMenuRow {
  display: flex;
  justify-content: space-between;
}
.mobileMenu {
  position: fixed;
  top: 52px;
  right: 0;
  left: 0;
  background: white;
  z-index: -1;
  height: calc(100vh - 52px);
  overflow-y: auto;
}
.mobileMenuContainer {
  margin: 32px 16px;
}
.menu-control {
  display: block;
}

@media (min-width: 1024px) {
  .mobileMenu {
    top: 80px;
    width: 100vw;
    height: calc(100vh - 80px);
  }
  .mobileMenuContainer {
    margin: 32px 64px;
  }
}

@media (min-width: 1440px) {
  .menu-control {
    display: none;
  }
  .mobileMenu {
    display: none;
  }
}
</style>
