import BaseRepository from "@/api/base.js";

const baseFactory = () => {
  return {
    fields: {},
    menu_section: { fields: {}, children: [] },
    footer_section: { fields: {}, contact: {}, children: [] },
    feedback_form: { fields: {} },
    connect_dialogue_section: { fields: {}, links: { feedback_form: [] } },
    subscribe_section: { fields: {} },
    solution_index: {},
    person_index: {},
    feedback_form_index: {},
    lang_code: "",
    alt_version: false,
  };
};

export default {
  state: {
    baseData: baseFactory(),
    isInit: false,
    isMainLoading: true,
    notificationDelay: false,
  },
  actions: {
    async fetchBase({ commit }) {
      try {
        const { data } = await BaseRepository.getBase();
        commit("setBaseData", data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  getters: {
    defaultFeedbackForm(state) {
      return state.baseData.feedback_form.fields;
    },
    defaultFeedbackFormName(state) {
      return state.baseData.feedback_form.node_name || "";
    },
    getFeedbackForm(state) {
      return (id) => state.baseData.feedback_form_index[id];
    },
    getCommonFields(state) {
      return state.baseData.fields;
    },
    getWindowTitle(state) {
      return (addTitle) => {
        if (!state.baseData.fields.title) return undefined;
        return addTitle
          ? `${state.baseData.fields.title} — ${addTitle}`
          : state.baseData.fields.title;
      };
    },
    getMenu(state) {
      return state.baseData.menu_section;
    },
    getFooter(state) {
      return state.baseData.footer_section;
    },
    getConnectDialogue(state) {
      return state.baseData.connect_dialogue_section;
    },
    getPresentationDialogue(state) {
      return state.baseData.presentation_dialogue_section;
    },
    getSolution(state) {
      return (solutionId) => state.baseData.solution_index[solutionId];
    },
    getPerson(state) {
      return (personId) => state.baseData.person_index[personId];
    },
    getLang(state) {
      return state.baseData.lang_code;
    },
    isAltVersion(state) {
      return state.baseData.alt_version;
    },
    isInit(state) {
      return state.isInit;
    },
    getMainLoading(state) {
      return state.isMainLoading;
    },
  },
  mutations: {
    setBaseData(state, payload) {
      const baseData = baseFactory();
      for (let prop in payload) {
        baseData[prop] = payload[prop];
      }
      state.baseData = baseData;
      state.isInit = true;
    },
    setLang(state, payload) {
      state.baseData.lang_code = payload;
    },
    setFinishMainLoading(state) {
      state.isMainLoading = false;
    },
  },
};
