<template>
  <v-row class="align-stretch">
    <v-col>
      <solution-card
        :solution-id="favSolutions[0] || 0"
        style="min-height: 420px"
        with-image
        reversed
      />
    </v-col>
  </v-row>
</template>

<script>
import SolutionCard from "@/components/SolutionCard/SolutionCard.vue";
export default {
  components: {
    SolutionCard,
  },
  props: {
    favSolutions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
