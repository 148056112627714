<template>
  <div class="crosspage d-flex align-center">
    <v-row
      class="crosspage__row crosspage__row--md justify-center align-center"
    >
      <v-col cols="12" lg="" class="crosspage__text">
        <div class="heading1 mb-5 mb-md-10">
          {{ $t("PageNotFound.NokiaKirillov.title") }}
        </div>
        <div class="mb-3 mb-md-6">
          {{ $t("PageNotFound.NokiaKirillov.text") }}
        </div>
        <v-row class="justify-center justify-sm-start">
          <v-col class="flex-grow-0">
            <header-menu
              :menu="menu"
              disable-search
              class="crosspage__menu mt-4"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg=""
        class="crosspage__image-container crosspage__kirillov-nokia"
      >
        <orbits-static
          class="crosspage__orbits d-none d-lg-block"
          :count="6"
          :sizes="[210, 320, 480, 750, 1020, 1220]"
        />
        <v-img :src="`${baseUrl}img/404/nokia-kirillov.png`" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HeaderMenu from "@/components/Header/HeaderMenu.vue";
import OrbitsStatic from "@/components/OrbitsStatic.vue";

export default {
  components: {
    HeaderMenu,
    OrbitsStatic,
  },
  data() {
    return {};
  },
  computed: {
    menu() {
      const menu = this.$store.getters.getMenu;
      const filteredChildren = menu.children.filter((item) =>
        ["solutions", "contacts"].includes(item.node_name)
      );
      return {
        fields: menu.fields,
        children: [
          {
            node_name: "",
            fields: { title: this.$t("Common.mainPageTitle"), href: "" },
          },
          ...filteredChildren,
        ],
      };
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/pages/CrossPages/CrossPage.scss";
.crosspage__kirillov-nokia {
  position: relative;
  max-width: 343px !important;
  margin-left: 350px;
  z-index: 1;
  .v-image {
    margin-bottom: -275px;
  }
}
.crosspage__orbits {
  margin-top: 275px;
  top: -40vh;
  left: -300%;
  width: 700%;
  height: 80vh;
  z-index: 0;
  overflow: hidden;
}
@media (max-width: 1439px) {
  .crosspage__kirillov-nokia {
    margin-left: 150px;
  }
}
@media (max-width: 1279px) {
  .crosspage__kirillov-nokia {
    margin-left: 0;
  }
}
</style>
