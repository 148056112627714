<template>
  <div class="sv-preloader">
    <div class="sv-preloader__text">{{ preloaderText }}</div>
    <div class="sv-preloader__line" :style="`width: ${pct}%`" />
    <div class="sv-preloader__header">
      <header-logo :locale="locale" />
    </div>
  </div>
</template>

<script>
import HeaderLogo from "@/components/Header/HeaderLogo.vue";
export default {
  name: "Preloader",
  components: { HeaderLogo },
  data() {
    return {
      finalizing: false,
      timer: null,
      pct: 0,
    };
  },
  computed: {
    locale() {
      if (window.location && window.location.hostname) {
        if (window.location.hostname.endsWith(".ru")) {
          return "ru";
        }
        if (window.location.hostname.endsWith(".org")) {
          return "en";
        }
        if (window.location.hostname.endsWith(".sc")) {
          return "en";
        }
      }
      if (window.navigator && window.navigator.language) {
        const lang = window.navigator.language.substr(0, 2);
        if (["ru", "ua", "be"].indexOf(lang) > -1) {
          return "ru";
        } else {
          return "en";
        }
      } else {
        return "ru";
      }
    },
    preloaderText() {
      const pctRnd = Math.round(this.pct);
      return `${pctRnd}%`;
    },
  },
  mounted() {
    this.startTimer();
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    finish() {
      this.finalizing = true;
      const local = this;
      setTimeout(() => {
        local.stopTimer();
        this.pct = 100;
        local.$emit("finalize");
      }, 500);
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.finalizing) {
          this.pct = this.pct + (100 - this.pct) / 4;
        } else {
          this.pct = this.pct + ((100 - this.pct) * (100 - this.pct)) / 500;
        }
      }, 100);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
  },
};
</script>
<style lang="scss" scoped>
.sv-preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  background-color: white;

  .sv-preloader__text {
    position: absolute;
    left: 64px;
    bottom: 20px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 88px;
    line-height: 96px;
    color: var(--v-corporateBlue-base);
  }

  .sv-preloader__line {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: var(--v-corporateBlue-base);
  }

  .sv-preloader__header {
    position: absolute;
    left: 0;
    top: 0;
    padding: 14px 16px;
    height: 52px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 1024px) {
  .sv-preloader {
    .sv-preloader__header {
      padding: 20px 64px;
      height: 80px;
    }
  }
}
</style>
