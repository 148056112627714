<template>
  <div style="position: relative">
    <v-row>
      <v-col
        xl="6"
        sm="12"
        style="z-index: 2; position: relative"
        class="pt-0 d-flex flex-column justify-center"
      >
        <v-row style="flex: 0 0 auto">
          <v-col class="pb-5 pt-0 text-left">
            <span class="heading1">{{ mainSectionIntro.fields.header }}</span>
          </v-col>
        </v-row>
        <v-row style="flex: 0 0 auto">
          <v-col class="pt-0 pb-5 text-left">
            <span>{{ mainSectionIntro.fields.subheader }}</span>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (mainSectionIntro.fields.catalog || '').length +
              (mainSectionIntro.fields.presentation || '').length >
            0
          "
          style="flex: 0 0 auto"
        >
          <v-col class="text-left">
            <v-btn
              v-if="(mainSectionIntro.fields.catalog || '').length > 0"
              depressed
              color="primary"
              x-large
              class="mobile-button-width"
              :href="mainSectionIntro.fields.catalog"
              >{{ mainSectionIntro.fields.button_catalog }}</v-btn
            ><v-btn
              v-if="(mainSectionIntro.fields.presentation || '').length > 0"
              class="mobile-button-width"
              color="primary"
              outlined
              x-large
              :href="mainSectionIntro.fields.presentation"
              style="background-color: #fff"
              >{{ mainSectionIntro.fields.button_presentation }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col
        xl="6"
        sm="12"
        class="justify-center px-0 py-0"
        align-self="center"
        style="height: fit-content; position: relative"
      >
        <orbits-animated style="top: -20%" />

        <image-slide-show
          :images="
            mainSectionIntro.children.map((img) => img.fields.screenshot)
          "
          :image-pad="`${baseUrl}img/mac.png`"
          :item-style="{
            left: '17%',
            top: '18%',
            width: '66%',
          }"
          @trigger-resize="$emit('trigger-resize')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ImageSlideShow from "@/components/ImageSlideShow.vue";
import OrbitsAnimated from "@/components/OrbitsAnimated.vue";
export default {
  name: "Notebook",
  components: { ImageSlideShow, OrbitsAnimated },
  props: {
    mainSectionIntro: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>

<style scoped>
.mobile-button-width {
  margin-bottom: 10px;
}
@media (min-width: 1024px) {
  .mobile-button-width:first-child {
    margin-right: 30px;
  }
  .mobile-button-width {
    margin-bottom: 0px;
  }
}
</style>
