<template>
  <div class="section">
    <v-row>
      <v-col class="page__title">
        <span class="heading1">{{ $t("Common.searchPage.title") }}</span>
      </v-col>
    </v-row>
    <v-row class="mb-4 mb-md-8">
      <v-col cols="12" md="9" lg="8" xl="6" class="col-xxl-5">
        <v-text-field
          v-model="term"
          clearable
          clear-icon="mdi-close-circle"
          class="align-center"
          hide-details
          height="45"
          @change="onInputChange"
          @blur="onInputBlur"
          @click:clear="onInputClear"
        >
          <template v-slot:prepend-inner>
            <v-btn icon @click="onInputChange">
              <v-icon class="mx-2">$vuetify.icons.search</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="searchData.results.length > 0">
      <v-col cols="12" lg="10" class="col-xxl-7">
        <div
          v-for="(item, index) in searchData.results"
          :key="index"
          class="mb-10 mb-md-10"
        >
          <div class="mb-1 mb-md-3">
            <router-link
              :to="getResultLink(item)"
              class="subtitle1 search-link"
            >
              <div v-html="getItemHeader(item)" />
            </router-link>
          </div>
          <div
            class="search-quote body2 mb-1 mb-md-3"
            v-html="item.quote.best_quote"
          />
          <div v-if="item.quote.cnt_more > 0" class="body3 textLight--text">
            {{ $tc("Common.searchPage.matches", item.quote.cnt_more) }}
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-else-if="searchData.requested.length > 0">
      <v-col>{{
        $t("Common.searchPage.notfound1", { term: searchData.requested })
      }}</v-col>
    </v-row>
  </div>
</template>

<script>
import SupplementRepository from "@/api/supplement.js";

const searchDataFactory = () => ({
  results: [],
  requested: "",
});

export default {
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(
        this.$t("Common.searchPage.title")
      ),
    };
  },
  data() {
    return {
      term: "",
      searchData: searchDataFactory(),
    };
  },
  computed: {
    getResultLink() {
      return (item) => {
        if (item.class_name == "solution") {
          return { name: "Solution", params: { ident: item.node_name } };
        } else {
          return { name: "Main" };
        }
      };
    },
    getItemHeader() {
      return (item) => {
        const type = item.class_name;
        const term = this.$t(`Common.searchPage.classes.${type}`);
        return `${item.fields.title} <span class="item-typ">(${term})</span>`;
      };
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.onInputChange();
      }
    },
    "$store.getters.getAppSearch"(newV) {
      this.term = newV;
      this.onInputChange();
    },
  },
  mounted() {
    this.term = this.$store.getters.getAppSearch;
    this.onInputChange();
  },
  methods: {
    async onInputChange() {
      if (this.term && this.term.length > 0) {
        try {
          const { data } = await SupplementRepository.search(this.term);
          this.searchData = data;
        } catch (error) {
          if (error.response && error.response.status == 422) {
            this.searchData = searchDataFactory();
          }
        }
      } else {
        this.searchData = searchDataFactory();
      }
      this.$emit("page-loaded");
    },
    onInputBlur() {
      if (!(this.term && this.term.length > 0)) {
        this.searchData = searchDataFactory();
      }
    },
    onInputClear() {
      this.term = "";
      this.onInputChange();
    },
  },
};
</script>
<style scoped lang="scss">
.search-link {
  color: var(--v-textBase-base);
  ::v-deep.item-typ {
    color: var(--v-primary-base);
  }
}
</style>
