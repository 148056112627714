<template>
  <div class="search-input" :class="active ? '' : 'hidden'">
    <v-text-field
      v-model="searchTerm"
      clearable
      clear-icon="mdi-close-circle"
      hide-details
      :height="height"
      :placeholder="$t('Common.searchHeaderPlaceholder')"
      @keyup.enter="doSearch"
      @blur="onBlur"
    >
      <template v-slot:prepend-inner>
        <v-btn icon @click="doSearch">
          <v-icon class="magnify mx-2">$vuetify.icons.search</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    resetOnBlur: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
      default: "",
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: "",
    };
  },
  methods: {
    doSearch() {
      if (this.active) {
        const { searchTerm } = this;
        this.searchTerm = "";
        this.$emit("on-search", searchTerm);
      } else {
        this.$emit("on-activate");
      }
    },
    onBlur() {
      if (this.resetOnBlur) {
        this.searchTerm = "";
      }
      this.$emit("on-blur");
    },
  },
};
</script>
<style lang="scss" scoped>
.search-input {
  display: inline-block;
  transition: width 0.25s ease-in-out;
}
.hidden.search-input {
  width: 0px;
}
.search-input > .v-input {
  padding-top: 0;
  margin-top: 0;
}
.search,
.close {
  display: inline-block;
}
.hidden .search,
.hidden .close {
  display: none;
}
.magnify {
  color: var(--v-corporateGrey-base) !important;
}
.magnify:active {
  color: var(--v-corporateBlue-base) !important;
}
.magnify:hover {
  background-color: transparent !important;
  color: var(--v-corporateBlue-base) !important;
}
.header--dark .magnify {
  color: white !important;
}
</style>
