<template>
  <article ref="articleref" v-html="html" />
</template>
<script>
import KkLink from "@/components/KkLink.vue";
import Vue from "vue";

export default {
  name: "KkDynamicHtml",
  props: {
    html: {
      type: String,
      required: true,
    },
  },
  watch: {
    async html(newV, oldV) {
      if (oldV.length > 0) {
        await this.$nextTick();
        this.compileDynamicHTML();
      }
    },
  },
  mounted() {
    this.compileDynamicHTML();
  },
  methods: {
    compileDynamicHTML() {
      this.compileLinks();
      this.compileNotifications();
    },

    compileLinks() {
      // выбираем все DOM-элементы в статье по имени тэга
      const elements = this.$refs.articleref.querySelectorAll("a");
      // получаем класс, который будет делать экземпляры компонента
      const componentClass = Vue.extend(KkLink);
      // по всем найденным в статье DOM-элементам
      elements.forEach((element) => {
        // получаем все свойства компонента из атрибутов DOM-элемента
        const link = element.getAttribute("href");
        // ссылки без ссылок — сжечь
        if (link === null) {
          element.remove();
          return true;
        }
        const propsData = {
          link,
          router: this.$router,
          innerHtml: element.innerHTML,
        };
        // создаем компонент сразу со свойствами
        const componentInstance = new componentClass({ propsData });
        // прикручиваем компонент в элемент, замещая outerHTML элемента
        componentInstance.$vuetify = this.$vuetify;
        componentInstance.$mount(element);
      });
    },

    compileNotifications() {
      // выбираем все DOM-элементы в статье по имени тэга
      const elements = this.$refs.articleref.querySelectorAll("notification");
      // по всем найденным в статье DOM-элементам
      elements.forEach((element) => {
        // получаем все свойства компонента из атрибутов DOM-элемента
        const replaceWith = document.createElement("div");
        replaceWith.classList.add(`kk-notification`);
        replaceWith.innerHTML = element.innerHTML;
        element.replaceWith(replaceWith);
      });
    },
  },
};
</script>
