<template>
  <div :class="{ dark }">
    <form-communication-modal v-model="showDialog" />
    <v-btn outlined class="mr-5 contactButton" @click="showDialog = true">{{
      menu.fields.button_connect
    }}</v-btn>
    <div v-if="!isAltVersion" class="d-flex align-center">
      <div
        v-for="(langEnabled, lang, index) in langs"
        :key="lang"
        class="lang-container"
        :class="{
          'mr-0': index == langsCount - 1,
          'mr-5': index != langsCount - 1,
          'lang--disabled': !langEnabled,
          'lang--chosen': $i18n.locale == lang,
        }"
        @click="changeLocale(lang, langEnabled)"
      >
        <div
          class="lang-icon"
          :style="`background-image: url(${baseUrl}img/flag-${lang}.svg`"
        />
        <div class="lang-icon__fader" />
      </div>
    </div>
  </div>
</template>

<script>
import FormCommunicationModal from "@/components/FormCommunicationModal.vue";
import BaseRepository from "@/api/base.js";
export default {
  components: { FormCommunicationModal },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    isAltVersion() {
      return this.$store.getters.isAltVersion;
    },
    langs() {
      return this.$store.getters.getAllLangs;
    },
    langsCount() {
      return Object.keys(this.langs).length;
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
  methods: {
    async changeLocale(localeNew, langEnabled) {
      if (!langEnabled) return false;
      if (this.$i18n.locale == localeNew) return false;
      await BaseRepository.setLang(localeNew);
      this.$store.commit("setLang", localeNew);
    },
  },
};
</script>
<style lang="scss">
.lang-container {
  position: relative;
  cursor: pointer;
  display: inline-block;

  .lang-icon {
    width: 20px;
    height: 20px;
    background-size: 18px;
    border-radius: 50%;
    background-position: center;
    border: 2px solid var(--v-corporateBorder-base);

    .header--dark & {
      border: 2px solid #454a51;
    }
  }

  .lang-icon__fader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.8);

    .dark & {
      background-color: var(--v-corporateGrey-base);
      opacity: 0.8;
    }
  }

  &.lang--chosen {
    cursor: inherit;

    .lang-icon {
      border: 2px solid var(--v-primary-base);
    }
  }

  &.lang--disabled {
    cursor: inherit;

    .lang-icon__fader {
      display: block;
    }
  }
}

.contactButton {
  color: var(--v-primary-base) !important;
}
.dark .contactButton {
  color: white !important;
}
</style>
