<template>
  <div class="crosspage d-flex align-end">
    <v-row class="crosspage__row mb-10 justify-center align-center">
      <v-col cols="12" lg="" class="crosspage__text">
        <div class="heading1 mb-5 mb-md-10">
          {{ formDataDefault.thank_you_title || "" }}
        </div>
        <div
          class="mb-3 mb-md-6"
          v-html="formDataDefault.thank_you_text || ''"
        />
        <div class="mb-2">
          <v-btn
            :to="{ path: $store.state.route.from.fullPath }"
            color="primary"
            outlined
            x-large
            exact
          >
            {{ formDataDefault.thank_you_button }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" lg="" class="crosspage__image-container">
        <div class="crosspage__image crosspage__image--cropped">
          <div class="crosspage__overlay">
            {{ time }}
          </div>
          <v-img :src="`${baseUrl}img/mobile-thanks.jpg`" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(
        this.formDataDefault.thank_you_title
      ),
    };
  },
  data() {
    return {
      time: "",
    };
  },
  computed: {
    formDataDefault() {
      return this.$store.getters.defaultFeedbackForm;
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
  created() {
    this.time = moment().format("H:mm");
    this.$emit("page-loaded");
  },
};
</script>
<style lang="scss" scoped>
@import "./CrossPage.scss";
</style>
