<template>
  <div
    v-bind="menuBind"
    class="menuDivItems"
    :class="{
      menuDivItemsHidden: !show,
      menuDivItemsHovered: hovered,
    }"
    v-on="menuOn"
  >
    <slot></slot>
    <div class="menuDivItemsBorder"></div>
  </div>
</template>

<script>
export default {
  props: {
    menuBind: {
      type: Object,
      default: () => {},
    },
    menuOn: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: true,
    },
    hovered: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.menuDivItems {
  flex: 0 0 fit-content;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}
.menuDivItemsBorder {
  background-color: rgba(0, 0, 0, 0);
  height: 2px;
  margin: auto;
  width: 0%;
  transition: all 0.25s ease-in;
}
.menuDivItems:hover .menuDivItemsBorder,
.menuDivItems:active .menuDivItemsBorder {
  background-color: var(--v-corporateBlue-base);
  width: 100%;
  transition: all 0.25s ease-in;
}
.menuDivItemsHovered .menuDivItemsBorder {
  background-color: var(--v-corporateBlue-base);
  width: 100%;
  transition: all 0.25s ease-in;
}
.menuDivItemsHidden {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
</style>
