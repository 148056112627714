<template>
  <div :class="{ dark }">
    <div
      v-for="(item, index) in menu.children"
      :key="index"
      class="menu-element"
    >
      <header-menu-category
        v-if="item.children && item.children.length > 0"
        :show="!showSearch"
        :item="item"
        :dark="dark"
      />

      <header-menu-item
        v-else
        :show="!showSearch"
        :hovered="currentRoute == `/${item.fields.href}`"
        :dark="dark"
      >
        <router-link class="menu" :to="getTo(item)">
          {{ item.fields.title }}
        </router-link>
      </header-menu-item>
    </div>

    <div v-if="!disableSearch" class="menu-element">
      <div class="search-input-relative">
        <div class="search-input-absolute">
          <header-menu-search
            :placeholder="menu.fields.search"
            reset-on-blur
            height="36"
            :active="showSearch"
            :dark="dark"
            class="search-header"
            @on-search="doSearch"
            @on-activate="showSearch = true"
            @on-blur="showSearch = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenuCategory from "./HeaderMenuCategory.vue";
import HeaderMenuItem from "./HeaderMenuItem.vue";
import HeaderMenuSearch from "./HeaderMenuSearch.vue";
export default {
  components: { HeaderMenuCategory, HeaderMenuItem, HeaderMenuSearch },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      showSearch: false,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
  methods: {
    doSearch(searchTerm) {
      if (searchTerm.length > 0) {
        this.showSearch = false;
        this.$emit("on-search", searchTerm);
      }
    },
    getTo(item) {
      const parts = item.fields.href.split("#");
      if (parts[1]) {
        return {
          path: `/${parts[0]}`,
          hash: parts[1],
        };
      } else {
        return { path: `/${item.fields.href}` };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-element {
  align-self: center;
  margin-left: 48px;
}

.menu-element:first-child {
  margin-left: 0;
}

@media (max-width: 639px) {
  .menu-element {
    margin-left: 24px;
  }
}
@media (max-width: 319px) {
  .menu-element {
    margin-left: 0;
  }
}

.dark .menu {
  color: white !important;
}

.search-input-relative {
  position: relative;
  height: 42px;
}

.search-input-absolute {
  position: absolute;
  right: 0;
  white-space: nowrap;
}

.search-header {
  width: 520px;
}
</style>
