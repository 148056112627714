<template>
  <div class="section__title">
    <v-row no-gutters>
      <v-col>
        <div v-if="isLoaded" class="heading2 text-left">{{ title }}</div>
        <v-skeleton-loader v-else type="heading" />
      </v-col>
      <v-col v-if="hasButton" class="flex-grow-0">
        <v-btn v-if="isLoaded" :to="to" color="primary" outlined x-large exact>
          {{ buttonTitle }}
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-skeleton-loader v-else type="button" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    to: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    isLoaded() {
      return this.title.length > 0;
    },
    hasButton() {
      return this.buttonTitle.length > 0;
    },
  },
};
</script>
