<template>
  <component :is="generatedComponent" v-if="generatedComponent !== null" />
</template>

<script>
import Matrix from "./404/Matrix";
import NokiaBent from "./404/NokiaBent";
import NokiaKirillov from "./404/NokiaKirillov";
import random from "lodash/random";

export default {
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle("404"),
    };
  },
  data() {
    return {
      generatedComponent: null,
      pages: [Matrix, NokiaBent, NokiaKirillov],
    };
  },
  created() {
    this.generatedComponent = this.pages[random(this.pages.length - 1)];
  },
  mounted() {
    this.$store.commit("setLangsAvailAll");
    this.$emit("page-loaded");
  },
};
</script>
