<template>
  <div>
    <article-with-contents
      v-if="pageLoaded"
      ref="refarticle"
      :contents="pageData.fields.contents"
      class="section"
    >
      <v-row>
        <v-col>
          <span class="heading1">{{ pageData.fields.title }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <article v-html="pageData.fields.full_text"></article>
        </v-col>
      </v-row>
    </article-with-contents>

    <form-communication :form-id="formId" />
  </div>
</template>

<script>
import SupplementRepository from "@/api/supplement.js";
import ArticleWithContents from "@/components/Article/ArticleWithContents.vue";
import FormCommunication from "@/components/FormCommunication.vue";

export default {
  components: { ArticleWithContents, FormCommunication },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        links: { feedback_form: [] },
      },
    };
  },
  computed: {
    formId() {
      if (this.pageData.links && this.pageData.links.feedback_form) {
        if (this.pageData.links.feedback_form.length > 0) {
          return this.pageData.links.feedback_form[0];
        }
      }
      return 0;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await SupplementRepository.getPartnership();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
