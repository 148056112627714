<template>
  <v-container v-if="pageLoaded" fluid class="px-0 py-0">
    <notebook class="mb-12" :main-section-intro="pageData.main_section_intro" />
    <favourite-solutions
      :fav-data="pageData.main_fav"
      with-title
      :with-footer="false"
    />
    <form-communication :form-id="pageData.links.feedback_form[0] || 0" />
  </v-container>
</template>

<script>
import Notebook from "@/components/Notebook.vue";
import FavouriteSolutions from "@/components/FavouriteSolutions/FavouriteSolutions.vue";
import FormCommunication from "@/components/FormCommunication.vue";
import MainRepository from "@/api/main.js";

export default {
  name: "Main",
  components: {
    Notebook,
    FavouriteSolutions,
    FormCommunication,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(null),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        main_section_intro: { fields: {}, children: [] },
        main_fav: { fields: {}, children: [] },
        links: { feedback_form: [] },
      },
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  created() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await MainRepository.getMain();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
  },
};
</script>
