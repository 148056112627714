<template>
  <v-dialog
    class="communication-dialog"
    content-class="dialog"
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-card class="card">
      <v-row>
        <v-col>
          <v-row no-gutters>
            <v-col class="text-right">
              <v-btn icon @click="$emit('input', false)">
                <v-icon color="rgba(0, 0, 0, 0.32)"> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="text-center">
              <span class="heading2" v-html="field('header')"></span>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-4 mb-6">
            <v-col class="text-center">
              <div v-html="field('text_before')" />
            </v-col>
          </v-row>
          <validation-observer ref="formModalValidator">
            <v-row>
              <v-col class="pt-0 pb-0 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  mode="eager"
                  :name="field('field_name')"
                >
                  <v-text-field
                    v-model="name"
                    :label="field('field_name')"
                    maxlength="50"
                    clearable
                    clear-icon="mdi-close-circle"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|phone"
                  mode="eager"
                  :name="field('field_phone')"
                >
                  <v-text-field
                    v-model="phone"
                    :label="field('field_phone')"
                    maxlength="25"
                    clearable
                    clear-icon="mdi-close-circle"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|email"
                  mode="eager"
                  :name="field('field_email')"
                >
                  <v-text-field
                    v-model="email"
                    :label="field('field_email')"
                    maxlength="25"
                    clearable
                    clear-icon="mdi-close-circle"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0 mb-10">
                <v-text-field
                  v-model="comment"
                  :label="field('field_comment')"
                  maxlength="100"
                  clearable
                  clear-icon="mdi-close-circle"
                />
              </v-col>
            </v-row>
          </validation-observer>
          <v-row class="align-center">
            <v-col class="flex-grow-0">
              <v-btn
                depressed
                x-large
                color="primary"
                :disabled="!agreement || !btnEnabled"
                @click="sendFeedback"
                >{{ field("button_send") }}</v-btn
              >
            </v-col>
            <v-col class="pt-2 d-flex">
              <v-checkbox v-model="agreement" class="mt-0 pt-0" hide-details />
              <div class="agreement-label caption-sc" @click="toggleAgreement">
                <span class="textLight--text">{{ field("agreement") }}</span>
                <span>
                  <router-link
                    :to="{ name: 'PrivacyPolicy' }"
                    class="caption-sc textLight--text text-decoration-underline"
                  >
                    {{ field("agreement_link") }}
                  </router-link>
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import SupplementRepository from "@/api/supplement.js";

export default {
  name: "FormCommunicationModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dialogue: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      btnEnabled: true,
      name: "",
      phone: "",
      email: "",
      comment: "",
      agreement: true,
    };
  },
  computed: {
    formDataDefault() {
      return this.$store.getters.defaultFeedbackForm;
    },
    modalData() {
      const connectDialogue =
        this.dialogue == null
          ? this.$store.getters.getConnectDialogue
          : this.dialogue;
      const id = connectDialogue.links.feedback_form[0];
      return id ? this.$store.getters.getFeedbackForm(id) : { fields: {} };
    },
    nodename() {
      if (this.modalData.node_name !== undefined) {
        return this.modalData.node_name;
      }
      return this.$store.getters.defaultFeedbackFormName;
    },
    field() {
      return (field) => {
        if (
          this.modalData.fields[field] !== undefined &&
          this.modalData.fields[field] !== "inherit"
        ) {
          return this.modalData.fields[field];
        }
        if (this.formDataDefault[field] !== undefined) {
          return this.formDataDefault[field];
        }
        return "";
      };
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        if (this.$refs.formModalValidator)
          this.$refs.formModalValidator.reset();
      }
    },
  },
  methods: {
    toggleAgreement(event) {
      const tagName = event.target.tagName || "";
      if (tagName.toLowerCase() == "a") {
        this.$emit("input", false);
      }
      this.agreement = !this.agreement;
    },
    async sendFeedback() {
      if (!(await this.$refs.formModalValidator.validate())) return;
      this.btnEnabled = false;
      const { name, phone, email, comment, nodename } = this;
      const location = window.location.href;
      const payload = { name, phone, email, comment, nodename, location };
      const { data } = await SupplementRepository.feedback(payload);
      this.btnEnabled = true;
      if (data.code == 0) {
        this.resetForm();
        this.$emit("input", false);
        if (this.$route.name == "Thanks") {
          this.$router.go();
        } else {
          this.$router.push({ name: "Thanks" });
        }
      } else {
        alert(this.$t(`Common.feedback.error${data.code}`));
      }
    },
    resetForm() {
      this.name = "";
      this.phone = "";
      this.email = "";
      this.comment = "";
      this.agreement = true;
      if (this.$refs.formModalValidator) this.$refs.formModalValidator.reset();
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .dialog {
  max-width: 560px;
  width: 100vw;
  margin: 0;
  max-height: 100% !important;
}

::v-deep .card {
  padding: 32px 48px;
}
.agreement-label {
  cursor: pointer;
}
</style>
