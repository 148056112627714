<template>
  <div>
    <div class="page__title">
      <v-row no-gutters class="align-center">
        <v-col class="flex-md-grow-0 mb-6 mb-lg-0" cols="12" lg="">
          <span class="heading1">{{ pageData.fields.title }}</span>
        </v-col>
      </v-row>
    </div>
    <v-container fluid class="px-0 py-0">
      <favourite-solutions :fav-data="pageData.main_fav" :with-footer="false" />
    </v-container>

    <form-communication :form-id="pageData.links.feedback_form[0] || 0" />
  </div>
</template>

<script>
import SolutionsRepository from "@/api/solutions.js";
import FavouriteSolutions from "@/components/FavouriteSolutions/FavouriteSolutions.vue";
import FormCommunication from "@/components/FormCommunication.vue";

export default {
  components: { FavouriteSolutions, FormCommunication },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageData: {
        fields: {},
        children: [],
        children_sort: [],
        links: { feedback_form: [] },
        main_fav: { fields: {}, children: [] },
      },
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    async initPage() {
      const { data } = await SolutionsRepository.getSolutionsList();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.$emit("page-loaded");
    },
  },
};
</script>
