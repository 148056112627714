<template>
  <article-with-contents
    v-if="pageLoaded"
    ref="refarticle"
    :contents="pageData.fields.contents"
    class="section"
  >
    <v-row>
      <v-col>
        <h2>{{ pageData.fields.title }}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <article v-html="pageData.fields.article" />
      </v-col>
    </v-row>
  </article-with-contents>
</template>

<script>
import CustomPagesRepository from "@/api/custompages.js";
import ArticleWithContents from "@/components/Article/ArticleWithContents.vue";

export default {
  components: { ArticleWithContents },
  props: {
    ident: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
      },
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await CustomPagesRepository.getPage(this.ident);
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
