var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 d-flex flex-column justify-center",staticStyle:{"z-index":"2","position":"relative"},attrs:{"xl":"6","sm":"12"}},[_c('v-row',{staticStyle:{"flex":"0 0 auto"}},[_c('v-col',{staticClass:"pb-5 pt-0 text-left"},[_c('span',{staticClass:"heading1"},[_vm._v(_vm._s(_vm.mainSectionIntro.fields.header))])])],1),_vm._v(" "),_c('v-row',{staticStyle:{"flex":"0 0 auto"}},[_c('v-col',{staticClass:"pt-0 pb-5 text-left"},[_c('span',[_vm._v(_vm._s(_vm.mainSectionIntro.fields.subheader))])])],1),_vm._v(" "),(
          (_vm.mainSectionIntro.fields.catalog || '').length +
            (_vm.mainSectionIntro.fields.presentation || '').length >
          0
        )?_c('v-row',{staticStyle:{"flex":"0 0 auto"}},[_c('v-col',{staticClass:"text-left"},[((_vm.mainSectionIntro.fields.catalog || '').length > 0)?_c('v-btn',{staticClass:"mobile-button-width",attrs:{"depressed":"","color":"primary","x-large":"","href":_vm.mainSectionIntro.fields.catalog}},[_vm._v(_vm._s(_vm.mainSectionIntro.fields.button_catalog))]):_vm._e(),((_vm.mainSectionIntro.fields.presentation || '').length > 0)?_c('v-btn',{staticClass:"mobile-button-width",staticStyle:{"background-color":"#fff"},attrs:{"color":"primary","outlined":"","x-large":"","href":_vm.mainSectionIntro.fields.presentation}},[_vm._v(_vm._s(_vm.mainSectionIntro.fields.button_presentation))]):_vm._e()],1)],1):_vm._e()],1),_vm._v(" "),_c('v-col',{staticClass:"justify-center px-0 py-0",staticStyle:{"height":"fit-content","position":"relative"},attrs:{"xl":"6","sm":"12","align-self":"center"}},[_c('orbits-animated',{staticStyle:{"top":"-20%"}}),_vm._v(" "),_c('image-slide-show',{attrs:{"images":_vm.mainSectionIntro.children.map((img) => img.fields.screenshot),"image-pad":`${_vm.baseUrl}img/mac.png`,"item-style":{
          left: '17%',
          top: '18%',
          width: '66%',
        }},on:{"trigger-resize":function($event){return _vm.$emit('trigger-resize')}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }