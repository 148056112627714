<template>
  <div>
    <v-row v-if="hasBreadcrumbs">
      <v-col>
        <breadcrumbs class="no-marker px-0 mb-9" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="2" class="mt-3">
        <slot name="aside"></slot>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6" xl="7" class="sv-article">
        <slot></slot>
      </v-col>
      <v-col class="d-none d-lg-block" lg="4" xl="3">
        <div class="sv-sticky-container" @wheel="onStickyMouseWheel">
          <div class="sv-sticky">
            <v-row
              v-for="(content, index) in contents"
              :id="`contents-link-${index}`"
              :key="index"
              no-gutters
              class="mt-5"
            >
              <v-col class="pl-0">
                <div
                  class="sv-chapter-title pl-2"
                  :class="{ 'sv-menu-active': isActive(index) }"
                >
                  <router-link
                    :to="{ hash: index }"
                    class="d-block textBase--text"
                  >
                    <div v-html="content" />
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-menu v-if="contentKeys.length > 0" v-model="navMenu" offset-x left>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="d-lg-none sv-menu-button"
            :class="{
              'sv-menu-button--primary': !navMenu,
              'sv-menu-button--white': navMenu,
            }"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon v-if="!navMenu" style="flex: 1 0 0" color="white"
              >mdi-dots-horizontal</v-icon
            >
            <v-icon v-else style="flex: 1 0 0" color="corporateGrey"
              >mdi-close</v-icon
            >
          </div>
        </template>
        <v-list class="py-0">
          <v-list-item
            v-for="(content, index) in contents"
            :key="index"
            class="sv-menu-item"
            link
            :href="`#${index}`"
          >
            <v-list-item-title><span v-html="content" /></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Article/Breadcrumbs.vue";
export default {
  components: {
    Breadcrumbs,
  },
  props: {
    contents: {
      type: Object,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      navMenu: false,
      observerOptions: {
        rootMargin: "0px 0px -50% 0px",
        threshold: 1,
      },
      observer: null,
      activeId: "",
    };
  },
  computed: {
    isActive() {
      return (id) => id == this.activeId;
    },
    hasBreadcrumbs() {
      return this.breadcrumbs.length > 0;
    },
    contentKeys() {
      return Object.keys(this.contents);
    },
  },
  methods: {
    initObserver() {
      const local = this;

      this.observer = new IntersectionObserver((entries) => {
        entries.some((entry) => {
          const targetId = entry.target.getAttribute("id");
          // entry уже внутри, значит активен именно он
          if (entry.isIntersecting) {
            local.activeId = targetId;
            return true;
            // entry на нижней границе, значит активен предыдущий
          } else if (
            entry.boundingClientRect &&
            entry.rootBounds &&
            entry.boundingClientRect.bottom > entry.rootBounds.bottom
          ) {
            const targetIndex = this.contentKeys.indexOf(targetId);
            local.activeId =
              targetIndex > 0 ? this.contentKeys[targetIndex - 1] : targetId;
            return true;
          }
          return false;
        });
      }, this.observerOptions);

      this.contentKeys.forEach((key) => {
        const target = document.querySelector("#" + key);
        if (target) {
          local.observer.observe(target);
        }
      });
    },

    onStickyMouseWheel(event) {
      const target = document.querySelector(".sv-sticky");
      if (target) {
        target.scrollTop += event.deltaY;
        const scrolledToTop = target.scrollTop <= 0;
        const scrolledToBottom =
          target.scrollTop + target.clientHeight >= target.scrollHeight;
        if (
          (event.deltaY < 0 && !scrolledToTop) ||
          (event.deltaY > 0 && !scrolledToBottom)
        ) {
          event.preventDefault();
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.sv-sticky-container {
  height: 100%;
}
.sv-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 80px;
  max-height: calc(100vh - 80px);
  padding-bottom: 20px;
  overflow-y: hidden;
}
.sv-article::v-deep h3 {
  margin-top: -60px;
  padding-top: 100px;
}

.sv-chapter-title {
  border-left: 4px solid transparent;
  border-color: transparent;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);

  &.sv-menu-active {
    border-color: #2196f3;
  }
}

.sv-menu-item {
  min-height: 27px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.sv-menu-button {
  width: 54px;
  height: 42px;
  position: fixed;
  right: 0;
  top: 80px;
  text-align: center;
  display: flex;
}
.sv-menu-button--primary {
  background: var(--v-primary-base);
}
.sv-menu-button--white {
  z-index: 9;
  background: white;
  box-shadow: 0px 7px 5px -5px rgb(0 0 0 / 20%),
    0px -5px 8px -5px rgb(0 0 0 / 12%);
}

@media (min-width: 1024px) {
  .sv-menu-button {
    top: 166px;
  }
}
</style>
