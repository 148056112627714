<template>
  <router-link
    v-if="solution"
    :to="`/${solutionLink}`"
    class="solution-card__routerlink-wrap"
  >
    <v-row
      ref="refSolutionRow"
      v-resize="calculateExplosion"
      no-gutters
      class="solution-card-row"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    >
      <div class="solution-background" :style="{ 'background-color': color }" />
      <div
        v-if="withImage"
        class="d-none d-md-block solution-orbits"
        :class="{ 'from-image-left': reversed, 'from-image-right': !reversed }"
      >
        <div class="solution-orbit solution-orbit__mercury" />
        <div class="solution-orbit solution-orbit__venus" />
        <div class="solution-orbit solution-orbit__earth" />
        <div class="solution-orbit solution-orbit__mars" />
      </div>
      <div
        class="solution-explosive"
        :class="explosionClass"
        :style="{
          'background-color': color,
          width: `${explosionRadiusCurrent * 2}px`,
          height: `${explosionRadiusCurrent * 2}px`,
          'margin-left': `-${explosionRadiusCurrent}px`,
          'margin-top': `-${explosionRadiusCurrent}px`,
        }"
      />
      <v-col
        v-if="withImage"
        cols="12"
        md="6"
        class="solution-image d-none d-md-flex align-center justify-center"
        :class="{ 'order-last': !reversed }"
      >
        <v-img
          :src="solutionField('image')"
          max-height="360px"
          eager
          contain
          @load="calculateExplosion"
        />
      </v-col>
      <v-col class="solution-content pa-5 pa-md-10 d-flex flex-column">
        <div class="subtitle1 mb-4 corporateGrey--text">
          {{ solutionField("title") }}
        </div>
        <div class="subtitle2 mb-4">{{ solutionField("subtitle") }}</div>
        <div
          v-if="withImage"
          class="solution-image d-md-none d-flex align-center justify-center"
        >
          <v-img
            class="my-8"
            :src="solutionField('image')"
            max-height="300px"
            contain
            @load="calculateExplosion"
          />
        </div>

        <div class="body1 mb-4" v-html="solutionField('annotation')" />
        <p class="mt-auto">
          <router-link
            :to="`/${solutionLink}`"
            class="btn-text solution-card-details-btn"
          >
            {{ buttonDetailsTitle }}
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </router-link>
        </p>
      </v-col>
    </v-row>
  </router-link>
</template>

<script>
import "./SolutionCard.scss";
export default {
  props: {
    // ID продукта
    solutionId: {
      type: Number,
      required: true,
    },
    // отображать ли картинку доп. колонкой
    withImage: {
      type: Boolean,
      default: false,
    },
    // если true, то картинка отобразится слева, а не справа
    reversed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      explosionRadius: 0,
      isHover: false,
      color: "rgba(33, 150, 243, 1)",
    };
  },
  computed: {
    explosionRadiusCurrent() {
      return this.isHover ? this.explosionRadius : 0;
    },
    explosionClass() {
      return {
        "from-center": !this.withImage,
        "from-image from-image__left": this.withImage && this.reversed,
        "from-image from-image__right": this.withImage && !this.reversed,
      };
    },
    buttonDetailsTitle() {
      return this.$store.getters.getCommonFields.button_details;
    },
    solutionField() {
      return (field) => (this.solution ? this.solution.fields[field] : "");
    },
    solutionLink() {
      return this.solution ? this.solution.node_name : "";
    },
    solution() {
      return this.$store.getters.getSolution(this.solutionId);
    },
  },
  updated() {
    this.$nextTick(() => {
      this.calculateExplosion();
    });
  },
  methods: {
    calculateExplosion() {
      if (!this.$refs.refSolutionRow) return;
      const breakpointMobile = window.innerWidth < 1024;
      let w = this.$refs.refSolutionRow.clientWidth;
      let h = this.$refs.refSolutionRow.clientHeight;
      if (this.withImage) {
        if (breakpointMobile) {
          w = w * 0.5;
          h = h - 300;
        } else {
          w = w * 0.75;
          h = h * 0.5;
        }
      } else {
        w = w * 0.5;
        h = h * 0.5;
      }
      this.explosionRadius = Math.sqrt(w * w + h * h) + 3;
    },
  },
};
</script>
