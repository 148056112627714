<template>
  <div class="header-logo">
    <img
      class="logo-img"
      alt=""
      :src="`${baseUrl}img/logo/logo-cyclopic-cities.svg`"
    />
  </div>
</template>

<script>
export default {
  props: {
    locale: {
      type: String,
      required: true,
    },
  },
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>
<style lang="scss" scoped>
.header-logo {
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  .logo-img {
    display: block;
    height: 24px;
  }
}

@media (min-width: 1024px) {
  .header-logo {
    flex: 0 0 auto;
    display: flex;
    margin-right: 20px;

    .logo-img {
      display: block;
      height: 40px;
    }
  }
}
</style>
